import * as React from "react"
import { Link } from "gatsby"
import Layout from "../layout"
import PostalAddress from "../components/address"

// markup
const ImprintPage = () => {
  return (
    <Layout>
      <title>Imprint</title>
      <p class="prompt prompt__imprint" id="cat"></p>

      <Link to="/" aria-label="Back to home" className="tohome">&larr; Back to home</Link>

			<section className="content">
	      <h1>Impressum</h1>
        <h2>Angaben gem&auml;ß § 5 TMG:</h2>
        <p>freshlabs GbR</p>
        <h3>Postanschrift &amp; Kontakt:</h3>
        <PostalAddress founder="Tim Isenheim" />
        <h3>Vertreten durch:</h3>
        <p>Tim Isenheim</p>
        <h3>Umsatzsteuer-Identifikationsnummer</h3>
        <p>DE814716956</p>
        <h2>Hinweise zur Website</h2>
        <h2>Information gem&auml;ß § 36 VSBG</h2>
        <p>Gem&auml;ß § 36 VSBG (Verbraucherstreitbeilegungsgesetz – Gesetz &uuml;ber die alternative Streitbeilegung in Verbrauchersachen) erkl&auml;rt der Betreiber dieser Website:</p>
        <p>Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
      </section>
    </Layout>
  )
}

export default ImprintPage
